/* Navbar */
nav {
    height: 70px !important;
    background-color: hsl(var(--hsl-blue-5)) !important;
    padding: 10px 30px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: space-between !important;
    z-index: 100 !important;
}

nav a {
    color: white;
}

nav div {
    display: flex;
    align-items: center;
}

nav div.right {
    justify-content: flex-end;
}

nav div.right > .ant-dropdown-trigger { 
    display: flex;
}

nav div.right > .ant-dropdown-trigger .ant-space div p {
    margin-bottom: 0;
 }

nav div h1 {
    font-size: 16px;
    height: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

nav div ul {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    font-size: 16px;
    margin-left: 2em;
    margin-bottom: 0;
}

nav div ul li {
    margin-right: 1em;
    position: relative;
}

nav div ul li:nth-of-type(1) {
    margin-right: 1.25em;
}

nav div ul li[data-active="active"]::after {
    content: "";
    display: block;
    left: 0;
    bottom: 0;
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: hsl(var(--hsl-blue-2));
}

nav div ul li a {
    transition: color 200ms ease-in-out;
}

nav ul li:nth-of-type(1) a:hover {
    color: hsl(var(--hsl-darkorange-1));
}

nav ul li:nth-of-type(2) a:hover {
    color: hsl(var(--hsl-green-1));
}

nav ul li:nth-of-type(3) a:hover {
    color: hsl(var(--hsl-red-1));
}

nav ul li:nth-of-type(4) a:hover {
    color: hsl(var(--hsl-purple-1));
}

nav ul li a i {
    margin-right: 0.25em;
}

.languages-single { 
    display: flex;
    justify-content: center;
    align-items: center;
}

.ant-dropdown-menu-item:hover {
    background-color: hsl(var(--hsl-b2)) !important;
}

.ant-dropdown-menu-item:not(:last-child) {
    margin-bottom: 0.5em !important;
}

.ant-dropdown-menu-item .ant-dropdown-menu-item-active {
    background-color: hsl(var(--hsl-b1)) !important;
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item-selected {
    color: white !important;
    background-color: hsl(var(--hsl-b1)) !important;
}

.languages-single > img {
    width: 32px;
    height: 24px;
    margin-right: 0.5em;
}

.languages-single > p {
    margin-bottom: 0;
}