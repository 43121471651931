/* Explicit Warning */
div.explicit-warning {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: white;
    padding: 12px;
}

.explicit-warning-footer {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
}

.explicit-warning-footer > button {
    height: 32px;
}

.explicit-warning-footer > button:not(:last-child) {
    margin-right: 1em;
}

div.explicit-warning i {
    font-size: 48px;
    margin-bottom: 0.5em;
}

div.explicit-warning strong {
    font-size: 24px;
    margin-bottom: 0.5em;
}

div.explicit-warning p {
    margin-bottom: 0.25em;
    text-align: center;
    text-wrap: balance;
}


/* Filter Button */
div.filter-button {
    cursor: pointer;
    display: flex;
    position: fixed;
    left: 0;
    top: 15%;
    padding: 8px 12px;
    font-size: 36px;
    color: hsl(var(--hsl-blue-5));
    background: white;
    border-radius: 1000000000000000000px;
    border-radius: 0 100000000px 10000000000px 0;
    z-index: 3;
    transition: all 200ms ease-in-out;
}

div.filter-button:hover {
    background-color: hsl(var(--hsl-blue-5));
    color: white;
}



/* Back To Top Button */
p.backToTop {
    cursor: pointer;
    display: flex;
    position: fixed;
    right: 20px;
    bottom: 60px;
    z-index: 3;
    font-size: 3.5em;
    margin: 0;
    border-radius: 10000000000px;
    background-color: hsl(var(--hsl-l3));
}

p.backToTop>i {
    color: white;
    transition: color 250ms ease-in-out;
}

p.backToTop>i:hover {
    color: hsl(var(--hsl-b1));
}


/* two side */
div.container > div.two-side {
    display: flex;
    flex-direction: row;
}

div.container > div.two-side > div {
    margin-right: 2em;
}



/* Beatmap Page */
ul.beatmap-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin: 32px auto;
}

ul.beatmap-list>li {
    padding: 0.75em;
    position: relative;
    overflow: hidden;
    display: inline-block;
    width: calc(50vw - 15vw);
    transition: transform 150ms ease, border 150ms ease;
}

ul.beatmap-list>li:hover {
    z-index: 2;
}

ul.beatmap-list>li>div {
    color: white;
}

ul.beatmap-list>li.notfound {
    height: 70vh;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
}

ul.beatmap-list>li.notfound>p {
    font-size: 1.5em;
    text-align: center;
    color: white;
    line-height: 1.25;
}

div.beatmap-single {
    border-radius: 12px;
    box-shadow: hsl(var(--hsl-b6)) 0 0 5px;
    width: 100%;
    position: relative;
    padding-top: 0;
    text-align: left;
    margin: 0;
    overflow: hidden;
    transition: box-shadow 150ms ease-in-out;
}

div.beatmap-single:hover {
    box-shadow: hsl(var(--hsl-b6)) 0 0 10px;
}

div.beatmap-single:hover::after {
    width: 5px;
}

div.beatmap-single::after {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    width: 0px;
    height: 100%;
    content: '';
    background-color: hsl(var(--hsl-l3));
    transition: width 150ms ease;
}

div.beatmap-single[data-isExpand=true] {
    box-shadow: 0 0 0 4px hsl(var(--hsl-l3)), hsl(var(--hsl-b6)) 0 0 5px;
}

div.beatmap-single[data-isplaying=true]::after {
    width: 5px;
}

div.beatmap-single div.card-header {
    background: center / cover no-repeat linear-gradient(to right, #000000bd, #ffe4e100), var(--bg);
    width: 100%;
    height: 136px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

div.beatmap-single div.card-header div.card-header-beatmapinfo {
    width: 100%;
    z-index: 2;
}

div.beatmap-single div.card-header div.card-header-beatmapinfo ul {
    display: flex;
    justify-content: space-between;
    position: relative;
}

div.beatmap-single div.card-header div.card-header-beatmapinfo ul li {
    display: flex;
    flex-direction: row;
}

div.beatmap-single div.card-header div.card-header-beatmapinfo ul li>* {
    padding: 0.1em 0.75em;
    border-radius: 0.75em;
    font-weight: 800;
    transition: all 125ms cubic-bezier(0.62, 0, 0.99, 0.49);
}

div.beatmap-single div.card-header div.card-header-beatmapinfo ul li>*:not(:last-child) {
    margin-right: 0.25em;
}

div.beatmap-single div.card-header div.beatmap-title {
    display: flex;
    flex-direction: column;
    font-weight: bold;
    z-index: 2;
}

div.beatmap-single div.card-header div.beatmap-title span.title {
    font-size: 1.5em;
    line-height: 1;
    display: block;
    text-overflow: ellipsis;
    overflow-x: clip;
}

div.beatmap-single div.card-header div.beatmap-title span.artist {
    color: hsl(var(--hsl-orange-1));
    text-shadow: 0 0 12px #000; 
}

div.beatmap-single div.card-header div.beatmap-title span.mapper {
    color: white;
    line-height: 1;
    font-size: 1em;
    text-shadow: 0 0 12px #000; 
    margin-bottom: 0.25em;
}

div.beatmap-single div.card-header div.beatmap-title span.mapper > a {
    color: hsl(var(--hsl-pink-1));
}

div.beatmap-single div.card-header div.beatmap-preview {
    transition: opacity 180ms ease;
    opacity: 0;
}

div.beatmap-single:hover div.card-header div.beatmap-preview {
    opacity: 1;
}

div.beatmap-single div.card-header div.beatmap-preview {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 136px;
    background: radial-gradient(transparent, hsl(var(--hsl-b3)));
}

div.beatmap-single div.card-header div.beatmap-preview button {
    z-index: 3;
    cursor: pointer;
    border: none;
    background: transparent;
    font-size: 2em;
    text-shadow: 0 0 10px black;
    padding: 10px 20px;
    background: radial-gradient(#00000000, #00000080);
    border-radius: 16px;
    width: 68px;
    transition: transform 150ms ease-in-out;
}

div.beatmap-single div.card-header div.beatmap-preview button:hover {
    transform: scale(0.95);
}

div.beatmap-single ul.card-main li.beatmap-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    padding: 7.5px 10px;
    background-color: hsl(var(--hsl-b5));
    color: white;
}

div.beatmap-single ul.card-main li.beatmap-info span a {
    color: hsl(var(--hsl-pink-1));
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats {
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    align-items: center;
    justify-content: flex-end;
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span,
div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>i {
    background-color: hsl(var(--hsl-b3));
    padding: 0.2em 0.5em;
    font-size: 0.9em;
    border-radius: 12px;
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span:not(:last-child) {
    margin-right: 0.25em;
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span:nth-of-type(1)>i {
    color: hsl(var(--hsl-pink-1));
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span:nth-of-type(2)>i {
    color: hsl(var(--hsl-orange-1));
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span:nth-of-type(3)>i {
    color: hsl(var(--hsl-blue-1));
}

div.beatmap-single ul.card-main li.beatmap-info div.card-header-info div.card-haeder-stats>span:nth-of-type(4)>i {
    color: hsl(var(--hsl-green-2));
}

div.beatmap-single ul.card-main li.beatmap-info div button {
    cursor: pointer;
    border: none;
    background: transparent;
    color: hsl(var(--hsl-darkorange-1));
    border: solid 1px transparent;
    transition: border 150ms ease-in-out, color 150ms ease-in-out;
}

div.beatmap-single ul.card-main li.beatmap-info div button:hover {
    color: hsl(var(--hsl-orange-1));
    border: solid 1px hsl(var(--hsl-orange-1));
}

.version-bg-btn { 
    width: 100%;
}

.version-bg-btn > i {
    margin-right: 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: hsl(var(--hsl-b5));
    font-size: 0.95em;
    padding: 7.5px 2.5px;
    border-top: hsl(var(--hsl-b2)) 2px solid;
}

div.beatmap-single ul.card-main li.beatmap-buttons>button,
div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button {
    transition: background-color 200ms ease-in-out;
    background-color: hsl(var(--hsl-b2));
    color: hsl(var(--hsl-white-1));
    border: none;
    border-radius: 10px;
    padding: 0 1em;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25px;
}

div.beatmap-single ul.card-main li.beatmap-buttons>button:hover,
div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button:hover {
    background-color: hsl(var(--hsl-b1));
}

div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button {
    padding: 0 0 0 1em;
}

div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button>button {
    color: hsl(var(--hsl-white-1));
    height: 25px;
    background-color: transparent;
    font-weight: 500;
    padding: 0;
    margin-right: 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button>button:hover {
    color: hsl(var(--hsl-white-1));
}

div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button>button.ant-dropdown-trigger {
    background-color: hsl(200, 10%, 40%);
    border-radius: 0 10px 10px 0;
    margin-right: 0;
}

div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button>button>i {
    margin-right: 0.25em;
}

div.beatmap-single ul.card-main li.beatmap-buttons>button:not(:last-child),
div.beatmap-single ul.card-main li.beatmap-buttons>div.ant-btn-group.ant-dropdown-button {
    margin-right: 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-buttons>button>p {
    margin-bottom: 0;
    margin-left: 0.25em;
    font-weight: 500;
}

/* div.beatmap-single ul.card-main li.beatmap-buttons > hr { width: 5px; height: 25px; border: none; background-color: hsl(var(--hsl-b2)); border-radius: 12px; margin: 0 5px; margin-right: 10px; } */
div.beatmap-single ul.card-main li.beatmap-list {
    background: hsl(var(--hsl-b4));
    font-size: 0.95em;
    padding: 7.5px 2.5px;
    border-top: hsl(var(--hsl-b2)) 2px solid;
}

div.beatmap-single ul.card-main li.beatmap-list>div {
    display: flex;
    flex-direction: row;
    padding: 2px 10px;
}

div.beatmap-single ul.card-main li.beatmap-list div button {
    font-size: 1.5em;
    display: inline-flex;
    border: none;
    background-color: transparent;
    cursor: pointer;
}

div.beatmap-single ul.card-main li.beatmap-list div button i {
    transition: transform 300ms ease-in-out;
    vertical-align: middle;
}

div.beatmap-single ul.card-main li.beatmap-list div button.expand i {
    transform: rotate(180deg);
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists {
    display: flex;
    margin: auto 0;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single:not(:last-child) {
    margin-right: 10px;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single i {
    font-size: 1.15em;
    line-height: 1;
    margin-right: 3px;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single ul {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single ul li:not(:last-child) {
    margin-right: 1.5px;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single ul li div.beatmap-version-single {
    background: var(--bg-color);
    width: 7.5px;
    border-radius: 10px;
    padding: 8px 0;
}

div.beatmap-single ul.card-main li.beatmap-list div div.version-lists div.version-list-single ul li div.beatmap-version-single:hover {
    outline: solid 2px hsl(var(--hsl-orange-1));
}

div.beatmap-single ul.card-main li.beatmap-list>ul {
    padding: 0 0.25em 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-list>ul:not(:last-child) {
    margin-bottom: 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li {
    transition: background-color 150ms ease;
    padding: 0 5px;
    border-radius: 15px;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li:hover {
    background-color: hsl(var(--hsl-b5));
}

div.beatmap-single ul.card-main li.beatmap-list>ul li>* {
    width: 100%;
    display: block;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li>span {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li span>div {
    display: flex;
    flex-direction: row;
    align-items: center;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li span>div>*:not(:last-child) {
    margin-right: 0.25em;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li span>div>button {
    background-color: hsl(var(--hsl-b5));
    border-radius: 15px;
    font-size: 0.8em;
    transition: color 200ms ease-in-out;
}

div.beatmap-single ul.card-main li.beatmap-list>ul li span>div>button:hover {
    color: hsl(var(--hsl-orange-1));
}

div.beatmap-single ul.card-main li.beatmap-list>ul li span i.faa {
    font-size: 1.1em;
}

div.beatmap-single ul.card-main li.beatmap-more-info {
    display: flex;
    justify-content: center;
    background-color: hsl(var(--hsl-b5));
    padding-bottom: 0.5em;
}

div.beatmap-single ul.card-main li.beatmap-more-info .btn {
    width: 200px;
}

div.beatmap-version-tooltip-single {
    font-weight: bold;
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.beatmap-version-tooltip-single div.beatmap-version-tooltip-info-header>*:not(:last-child) {
    margin-right: 0.5em;
}

div.beatmap-version-tooltip-single div.beatmap-version-tooltip-info-header i {
    font-size: 1.1em;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-middle {
    display: flex;
    flex-direction: row;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-middle li {
    display: flex;
    align-items: center;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-middle li:not(:last-child) {
    margin-right: 0.5em;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-middle li span {
    color: hsl(var(--hsl-orange-1));
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-end {
    width: 380px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 0;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-end li {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 0.9em;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-end li:nth-child(2n+2) {
    margin-left: 1em;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-end li>span {
    margin-right: 1em;
}

div.beatmap-version-tooltip-single ul.beatmap-version-tooltip-info-end li div {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
    transform: scale(0.9);
}

ul.beatmap-list>li.loading {
    top: 0;
    position: absolute;
    width: 1200px;
}

ul.beatmap-list>li.loading[data-loading=false] {
    width: 0px;
}

div.beatmap-list-loading {
    font-size: 20px;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.8);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1562345;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

div.beatmap-list-loading>* {
    color: white;
}

div.beatmap-list-loading>i {
    margin-bottom: 0.5em;
    font-size: 3em;
    animation: loading-spinner 2000ms ease-in-out infinite;
}


/* Download Page */
div.download-page {
    display: flex;
    height: 90vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

div.download-page div.owo {
    display: flex;
    flex-direction: column;
    align-items: center;
}

div.download-page div.owo h1 {
    color: white;
    margin-bottom: 20px;
    font-size: 32px;
}

div.download-page div.owo h2 {
    color: rgb(196, 196, 196);
    font-size: 18px;
    margin: 0;
}

div.download-page button {
    position: absolute;
    display: flex;
    height: 50px;
    width: 500px;
    align-items: center;
    justify-content: center;
    font-family: TmoneyRoundWindRegular !important;
    border: none;
    background: transparent;
    color: black;
    font-size: 18px;
    border-radius: 12px;
    transition: all 150ms ease-in-out;
    cursor: pointer;
    left: 50%;
    top: 50%;
    transform: translate(-50%, 300%);
}

div.download-page button:hover {
    color: #b9ff6d;
}

@keyframes Rotate {
    100% {
        transform:  rotate(360deg);
    }
}