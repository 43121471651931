/* Footer */
footer {
    width: 100%;
    height: var(--footer-height);
    background-color: hsl(var(--hsl-b5));
    box-shadow: 0px 0px 10px #000;
    padding: 10px 20px !important;
    display: flex !important;
    position: fixed;
    bottom: 0;
    font-size: 14px;
    flex-direction: row;
    justify-content: space-between;
    z-index: 7;
}

footer span {
    color: white;
    font-size: inherit;
}

footer ul {
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: left;
    align-items: center;
    font-size: inherit;
    margin-left: 2em;
}

footer a {
    color: white;
}

footer ul li {
    margin-right: 1em;
}

footer ul li:nth-of-type(1) {
    margin-right: 1.25em;
}

footer ul li:nth-of-type(1) {
    margin-right: 1.25em;
}

footer div ul li a {
    transition: color 200ms ease-in-out;
}

footer ul li:nth-of-type(1) a:hover {
    color: hsl(var(--hsl-darkorange-1));
}

footer ul li:nth-of-type(2) a:hover {
    color: hsl(var(--hsl-green-1));
}

footer ul li:nth-of-type(3) a:hover {
    color: hsl(var(--hsl-red-1));
}

footer ul li:nth-of-type(4) a:hover {
    color: hsl(var(--hsl-purple-1));
}

footer ul li a i {
    margin-right: 0.25em;
}